/*Door Choice Component*/

.doorImg{
    max-width: 100%;
}

button{
    height: 100%;
    padding: 0;
    width:100%;

}

.doorchoices h5{
    padding: 5px;
    margin-bottom: 0;
    margin: auto!important;
}
@media (min-width: 768px) {
    .featured_image{
        max-width: 200px;
    }
}
.featured_image{
    width: 100% !important;
    height: 200px!important;
    object-fit: cover;
}

.total_image{
    width: 100%!important;
    object-fit: cover;
}

input.hoogte, input.breedte{
    width: 100px;
}
.bg-dudok{
    background-color: #729992;
}

.border-dudok{
    border-color:#729992!important;
}

.bg-9005{
    background-color: #1e2322;
}

.bg-7021{
    background-color: #4f5655;
}

.sidebar{
    top:250px;
}

a:hover{
    text-decoration: none;
    cursor: hand;
}
.nummer_bolletje{
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
}

.btnKopen{
    border-radius: 20px;
}
.info.tekst .bg-dark{
    background-color: #B1ADA6 !important;
}
.info.tekst .text-dark{
    color: #B1ADA6 !important;
}
.form-control{
    border: 1px solid #B1ADA6 !important;
}
.font-14px{
    font-size: 14px;
}
.sidebar h4{
    display: inline-block;
    width: 88%;
}
.sidebar svg{
    color: white;
    position: absolute;
    height: 100%;
    width: 9.5% !important;
    right: 10px;
}
#doorchoice button{
    border: 2px solid #729992;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
}

#totalresult ul{
    list-style-type: disc;
}
.kleur-bolletje{
    height: 100px;
    border-radius: 3px;
}

.form-control:focus{
    box-shadow: 0 0 0 0.2rem #72999261;
}
h4{
    font-size: 1.3rem !important;
}
h3{
    font-size: 1.3em !important;
}

.sidebar h4 {
    font-size: 1rem!important;
}

.sidebar h2{
    font-size: 1.5rem!important;
    margin:auto!important;
}

.sidebar .col-2{
    padding: 0!important;
}

h5.custom-control-label{
    font-size: 1rem!important;
}

.ral-text{
    position: absolute;
    top: 5px;
    color: white;
    margin-left: -35px;
    left: 50%;
}

.bg-active{
    outline: #719892 solid 8px!important;
    background:#719892!important;
}

.bg-active h5{
    color: white!important;
}

.info.tekst h5{
    width: 24px!important;
    height: 24px!important;
    text-align: center!important;
}